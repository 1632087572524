<template>
  <div>
    <b-modal
      id="modal-payment-categories"
      size="xl"
      title="Pilih Produk Layanan"
      hide-footer
    >
      <div class="row">
        <div class="col-md-9">
          <Table :selectedItem="dataSelected" @chooseItem="chooseItem" />
        </div>
        <div class="col-md-3">
          <b-table
            striped
            hover
            :items="dataSelected"
            :fields="fields"
            responsive
          >
            <template #table-colgroup="scope">
              <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="{
                  width: field.key === 'actions' ? '17%' : '',
                  width: field.key == 'generic_name' ? '15%' : '',
                }"
              />
            </template>
            <template #cell(name)="data">
              <span
                >{{ data.item.name }}
                <b
                  >({{ parseInt(data.item.price).toLocaleString("id-ID") }})</b
                ></span
              >
            </template>
            <template #cell(actions)="data">
              <b-button
                size="sm"
                class="btn-danger mr-1"
                style="padding: 5px !important"
                @click="deleteData(data.item.id)"
                ><i class="fas fa-trash px-0"></i
              ></b-button>
            </template>
          </b-table>
        </div>
      </div>

      <!-- <Table
        purpose="modal"
        modalPurpose="modal"
        :isHospitalTransaction="isHospitalTransaction"
        :selectedItem="selectedItem"
        :checkbox="checkbox"
        :isSortcut ="isSortcut"
        :isAbjad ="isAbjad"
        @chooseMedicine="chosenMedicine"
        @packageMedicine="packageMedicine"
        :secondPurpose="secondPurpose"
      /> -->
    </b-modal>

    <b-modal
      id="modal-commision-amount"
      size="md"
      :title="'Besaran Komisi - '+display.detail"
      hide-footer
    >
      <div class="row">
        <div class="col-md-12 col-sm-12">
          <!-- doctor commission -->
          <b-form-group
            id="input-group-doctor-commission"
            :label="`Komisi Dokter (${isPercent ? '%' : 'Rp'}):`"
            label-for="input-doctor-commission"
          >
            <b-input-group>
              <template #prepend>
                <b-input-group-text
                  squared
                  @click="btnIsPercentToggle"
                  class="bg-light-primary font-weight-bold text-primary"
                  >{{ isPercent ? "%" : "Rp" }}</b-input-group-text
                >
              </template>
              <b-form-input
                id="input-doctor-commission"
                @keyup="doctorCommissionOnKeyUp"
                v-model="formatedDoctorCommission"
                placeholder="Komisi Dokter..."
              ></b-form-input>
            </b-input-group>
            <small class="text-danger">{{ error.doctor_commission }}</small>
          </b-form-group>
        </div>
        <div class="col-md-12 col-sm-12">
          <!-- hospital income -->
          <b-form-group
            id="input-group-hospital-income"
            :label="`Pendapatan Rumah Sakit (${isPercent ? '%' : 'Rp'}):`"
            label-for="input-hospital-income"
          >
            <b-input-group>
              <template #prepend>
                <b-input-group-text
                  squared
                  @click="btnIsPercentToggle"
                  class="bg-light-primary font-weight-bold text-primary"
                  >{{ isPercent ? "%" : "Rp" }}</b-input-group-text
                >
              </template>
              <b-form-input
                id="input-hospital-income"
                @keyup="hospitalIncomeOnKeyUp"
                v-model="formatedHospitalIncome"
                placeholder="Pendapatan Rumah Sakit..."
              ></b-form-input>
            </b-input-group>
            <small class="text-danger">{{ error.hospital_income }}</small>
          </b-form-group>

          <!-- save -->
          <b-button type="button" @click="saveCommisionAmount" variant="primary"
            >Simpan</b-button
          >
          <!-- cancle -->
          <b-button
            type="button"
            class="ml-2"
            variant="default"
            @click="hideModalCancel"
          >
            Cancel
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import Table from "@/component/masters/payment-categories/TableModal.vue";

export default {
  props: {
    selectedItem: Array,
    edit: Boolean,
    purpose: String,
    // checkbox: Boolean,
    // isHospitalTransaction: Boolean,
    // isSortcut: Boolean,
    // isAbjad: Boolean,
    // secondPurpose:{
    //   type: String,
    //   default: 'listing'
    // }
  },

  data() {
    return {
      dataSelected: [],

      // fields
      fields: [
        {
          key: "actions",
          label: "",
          thClass: "nowrap",
          tdClass: "p-1",
        },
        {
          key: "name",
          label: "Produk",
          sortable: true,
          // thClass: "nowrap",
          // tdClass: "p-1 nowrap",
        },
      ],

      data: {
        price: 0,
        doctor_commission: 0,
        hospital_income: 0,
      },

      display:{
        detail:"",
      },

      temporarySelectedData:[],

      // other
      totalPercentage: 100,
      isPercent: false,

      error: {
        doctor_commission: "",
      },
    };
  },

  components: {
    Table,
  },

  computed: {
    formatedPrice: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ""), 10);
        if (!Number.isNaN(newValue)) {
          this.data.price = newValue.toLocaleString("id-ID");
        } else {
          this.data.price = "";
        }
      },
      get() {
        return this.data.price;
      },
    },

    formatedDoctorCommission: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ""), 10);
        if (!Number.isNaN(newValue)) {
          this.data.doctor_commission = newValue.toLocaleString("id-ID");
        } else {
          this.data.doctor_commission = "";
        }
      },
      get() {
        return this.data.doctor_commission;
      },
    },

    formatedHospitalIncome: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ""), 10);
        if (!Number.isNaN(newValue)) {
          this.data.hospital_income = newValue.toLocaleString("id-ID");
        } else {
          this.data.hospital_income = "";
        }
      },
      get() {
        return this.data.hospital_income;
      },
    },
  },

  methods: {
    hideModalCancel(){
      this.$bvModal.hide("modal-commision-amount");
      this.$bvModal.show("modal-payment-categories");
      this.data.doctor_commission = 0
      this.data.hospital_income = 0
    },

    saveCommisionAmount(){

      if(this.isPercent == false){
        this.temporarySelectedData.doctor_commission = parseInt(this.data.doctor_commission.replace(/\D/g, ""), 10).toLocaleString('id-ID')
        this.temporarySelectedData.hospital_income = parseInt(this.data.hospital_income.replace(/\D/g, ""), 10).toLocaleString('id-ID')
      }else{
        this.temporarySelectedData.doctor_commission = parseInt(this.data.doctor_commission, 10)
        this.temporarySelectedData.hospital_income = parseInt(this.data.hospital_income, 10)
      }

      this.temporarySelectedData.payment_category_id = this.temporarySelectedData.id
      this.temporarySelectedData.doctor_id = this.$route.params.id
      this.temporarySelectedData.isPercent = this.isPercent

      // if (this.dataSelected.findIndex((x) => x.id == this.temporarySelectedData.id) < 0) {
      //    this.dataSelected.push(this.temporarySelectedData);
      // }

      this.$emit('saveDataDoctor',this.temporarySelectedData)
      

      this.hideModalCancel()
    },

    doctorCommissionOnKeyUp() {
      if (this.formatedPrice != 0) {
        if (this.isPercent) {
          this.totalPercentage = 100;
          this.totalPercentage -= this.data.doctor_commission;
          this.data.hospital_income = this.totalPercentage;
        } else {
          let share = parseInt(this.data.price, 10);
          share -= parseInt(this.data.doctor_commission.replace(/\D/g, ""), 10);
          this.data.hospital_income = !isNaN(share)
            ? share.toLocaleString("id-ID")
            : "";
        }
      }
    },

    hospitalIncomeOnKeyUp() {
      if (this.formatedPrice != 0) {
        if (this.isPercent) {
          this.totalPercentage = 100;
          this.totalPercentage -= this.data.hospital_income;
          this.data.doctor_commission = this.totalPercentage;
        } else {
          let share = parseInt(this.data.price, 10);
          share -= parseInt(this.data.hospital_income.replace(/\D/g, ""), 10);
          this.data.doctor_commission = !isNaN(share)
            ? share.toLocaleString("id-ID")
            : "";
        }
      }
    },

    btnIsPercentToggle() {
      // this.error.doctor_commission = "";
      // this.error.hospital_income = "";
      this.totalPercentage = 100;
      this.data.doctor_commission = "";
      this.data.hospital_income = "";
      this.isPercent ? (this.isPercent = false) : (this.isPercent = true);
    },

    chooseItem(value) {
      if (this.purpose == "doctorCommision") {
        this.$bvModal.show("modal-commision-amount");
        this.$bvModal.hide("modal-payment-categories");
    
        this.data.price = value.price
        this.display.detail = value.name+" ("+parseInt(value.price).toLocaleString('id-ID')+")"
        this.temporarySelectedData = value
        this.saveCommisionAmount(value)

        // if (this.dataSelected.findIndex((x) => x.id == value.id) < 0) {
        //   this.dataSelected.push(value);
        // }
      } else {
        if (this.dataSelected.findIndex((x) => x.id == value.id) < 0) {
          value.quantity = 1
          this.dataSelected.push(value);
        }
        this.$root.$emit("updateService", this.dataSelected);
      }

    },

    deleteData(id) {
      let a;
      for (a = 0; a < this.dataSelected.length; a++) {
        if (this.dataSelected[a].id == id) {
          this.dataSelected.splice(a, 1);
        }
      }

      this.$root.$emit("updateService", this.dataSelected);
    },
  },
  mounted() {
    this.dataSelected = this.selectedItem;

    this.$root.$on("updateItemService", (value)=>{
      this.dataSelected = value
    })
  },
};
</script>

<style>
</style>