<template>
  <div>
    <b-modal id="modal-medicine" size="xl" title="Pilih Obat" hide-footer>
      <div class="row">
        <div class="col-md-9">
          <Table
            purpose="modal"
            modalPurpose="modal"
            :isHospitalTransaction="isHospitalTransaction"
            :selectedItem="selectedItem"
            :checkbox="checkbox"
            :isSortcut="isSortcut"
            :isAbjad="true"
            @chooseMedicine="chosenMedicine"
            :secondPurpose="secondPurpose"
          />
        </div>
        <div class="col-md-3">
          <b-table
            striped
            hover
            :items="selectedData"
            :fields="fields"
            responsive
          >
            <!-- :style="{ width: field.key === 'actions' ? purpose == 'master' ? '17%' : '' : '' , width: field.key === 'responsible_doctor' ? '10%' : '' }" -->
            <template #table-colgroup="scope">
              <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="{
                  width: field.key === 'actions' ? '17%' : '',
                  width: field.key == 'generic_name' ? '15%' : '',
                }"
              />
            </template>
            <template #cell(name)="data">
              <span
                >{{ data.item.name }} <b>({{ data.item.quantity }})</b></span
              >
            </template>
            <template #cell(actions)="data">
              <b-button
                size="sm"
                class="btn-danger mr-1"
                style="padding: 5px !important"
                @click="deleteData(data.item.id)"
                ><i class="fas fa-trash px-0"></i
              ></b-button>
            </template>
          </b-table>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Table from "@/component/medicine/Table.vue";

export default {
  props: {
    selectedItem: Array,
    checkbox: Boolean,
    isHospitalTransaction: Boolean,
    isSortcut: Boolean,
    secondPurpose: {
      type: String,
      default: "listing",
    },
  },

  data() {
    return {
      selectedData: [],
      // fields
      fields: [
        {
          key: "name",
          label: "Obat & alkes dipakai(Jml)",
          sortable: true,
          thClass: "nowrap",
          tdClass: "p-1 nowrap",
        },
        {
          key: "actions",
          label: "",
          thClass: "nowrap",
          tdClass: "p-1",
        },
      ],
    };
  },

  components: {
    Table,
  },

  methods: {
    deleteData(id) {
      let a;
      for (a = 0; a < this.selectedData.length; a++) {
        if (this.selectedData[a].id == id) {
          this.selectedData.splice(a, 1);
        }
      }
      // this.selectedItem = this.selectedData
      this.$root.$emit("updateMedicine", this.selectedData);
      this.$emit("packageMedicine", this.selectedData);
    },

    chosenMedicine(value) {
      this.$emit("chosenMedicine", value, this.selectedItem);
    },
  },
  mounted() {
    this.selectedData = this.selectedItem;
  },
};
</script>

<style>
</style>