<template>
  <div>

    <div class="row align-items-center justify-content-end mt-n3">
      <div class="col-md-4 col-12">
        <b-input-group>
          <template #prepend>
            <b-input-group-text squared>
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
          <b-form-input
            type="text"
            v-model="filter.name"
            placeholder="Cari Berdasar Nama"
            @keyup="filterByName"></b-form-input>
        </b-input-group>
      </div>
    </div>
    <b-table
      striped
      hover
      class="mt-3"
      responsive
      :items="items"
      :fields="fields">
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.key === 'actions' ? '20%' : '' }" />
      </template>
      <template #cell(price)="data">
        {{ parseInt(data.item.price).toLocaleString('id-ID') }}
      </template>
      <template #cell(doctor_commission)="data">
        {{ data.item.doctor_commission }}
      </template>
      <template #cell(hospital_income)="data">
        {{ data.item.hospital_income }}
      </template>
      <template #cell(actions)='data'>
        <div class="d-flex">
          <!-- <template v-if="purpose == 'master'">
            <b-button
              size="sm"
              class="mr-1 btn-primary"
              variant="primary"
              v-b-tooltip.hover
              title="Komisi Dokter"
              v-if="btnAddDoctorCommission"
              @click="$router.push({ path: '/payment-category/add-doctor-commision/' + data.item.id })"
            ><i class="fas fa-user-md px-0"></i></b-button>
            <b-button
              size="sm"
              class="mr-1 btn-info"
              v-b-tooltip.hover
              title="Detail"
              @click="$router.push({ path: '/payment-category/detail/' + data.item.id })"
            ><i class="fas fa-eye px-0"></i></b-button>
            <b-button
              size="sm"
              class="mr-1 btn-success"
              v-b-tooltip.hover
              title="Edit"
              v-if="btn"
              @click="$router.push({ path: '/payment-category/edit/' + data.item.id })"
            ><i class="fas fa-edit px-0"></i></b-button>
            <b-button
              size="sm"
              class="btn-danger"
              v-b-tooltip.hover
              title="Hapus"
              v-if="btn"
              @click="btnDeleteOnClick(data.item.id)"
            ><i class="fas fa-trash px-0"></i></b-button>
          </template> -->
          <template v-if="purpose == 'modalDoctor'">
            <b-button
              size="sm"
              class="mr-1 btn-primary"
              variant="info"
              @click="chooseItem({ id: data.item.id, name: data.item.name, price: data.item.price })">Pilih</b-button>
          </template>
          <template v-if="purpose != 'modalDoctor'">
            <b-form-checkbox
              :checked="data.item.checked"
              :id="`checkbox-pick-payment-category-${data.item.id}`"
              :name="`checkbox-pick-payment-category-${data.item.id}`"
              @change="chooseItem({ id: data.item.id, name: data.item.name, price: data.item.price })">
            </b-form-checkbox>
          </template>
        </div>
      </template>
    </b-table>
    <b-pagination
      v-if="items.length != 0"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      aria-controls="my-table"
      @page-click="pageOnClick"
      class="mt-4"></b-pagination>

  </div>
</template>

<script>

import { debouncer } from "@/core/modules/Helper.js"
import module from '@/core/modules/CrudModule.js'

export default {

  props: {
    purpose: String,
    selectedItem: Array,
    dataId: Number
  },

  data() {
    return {
      // Filter
      filter: {
        name: ''
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      lastCurrentPage: 1,
      totalRows: 0,
      // Table Head
      fields: [
        {
          key: "number",
          label: '#',
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "price",
          label: "Harga",
          sortable: true,
        },
        // {
        //   key: "doctor_commission",
        //   label: "Komisi Dokter",
        //   sortable: true,
        // },
        // {
        //   key: "hospital_income",
        //   label: "Pendapatan Rumah Sakit",
        //   sortable: true,
        //   thClass: 'text-truncate',
        //   // thStyle: { maxWidth: '90px', overflowX: 'auto' }
        // },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
      // User Access
      btn: false,
      btnAddDoctorCommission: false
    }
  },

  methods: {

    pageOnClick(evt, page) {
      evt.preventDefault()
      this.currentPage = page
      this.lastCurrentPage = page
      this.pagination()
    },

    chooseItem(value) {
      this.$emit("chooseItem", value)
    },

    async pagination() {
      let filterParams = ""
      // console.log("purpose ===============",this.purpose);
      // if(this.purpose == "modalCommission"){
      //   filterParams = `&name=${this.filter.name}&modal=true&modal_commission=true`
      // }else{
      filterParams = `&name=${this.filter.name}&modal=true`
      // }

      let response = await module.paginate('payment-categories/modal/' + this.dataId, `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
      let pagination = response.meta.pagination
      this.totalRows = pagination.total

      let a, b
      b = this.perPage * (this.currentPage - 1) + 1
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a
      }
      this.items = response.data
      this.setCheckedItem()
    },

    setCheckedItem() {
      let found
      this.items.forEach(value => {
        found = this.selectedItem.find(x => x.id == value.id)
        if (typeof found === 'undefined') {
          value.checked = false
        } else {
          value.checked = true
        }
      })
    },

    filterByName() {
      debouncer(() => {
        this.currentPage = 1
        if (this.filter.name == "") {
          this.currentPage = this.lastCurrentPage
        }
        this.pagination()
      }, 500)
    },

    async btnDeleteOnClick(id) {
      // Delete Data
      let result = await module.delete('payment-categories/' + id)
      // If Deleted
      if (result) {
        this.pagination()
      }
    },

    setActiveButton() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)

      let a
      for (a = 0; a < access_right.length; a++) {
        if (access_right[a] == "6009") {
          this.btn = true
        }
        if (access_right[a] == "6023") {
          this.btnAddDoctorCommission = true
        }
      }
    },

  },

  watch: {
    selectedItem: function (newVal) {
      this.setCheckedItem()
    }
  },

  mounted() {
    // Get Data
    this.pagination()
    this.setActiveButton()

  }

}
</script>

<style></style>