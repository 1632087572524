<template>
  <div>
    <!-- Component List -->
    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body v-if="dataLoaded">
            <div class="overflow-auto">
              <b-table striped hover :items="items" :fields="fields">
                <template #table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{ width: field.key === 'actions' ? '15%' : '' }"
                  />
                </template>
                <template #cell(photo)="data">
                  <div
                    class="
                      d-flex
                      justify-content-start
                      align-items-center
                      image-container
                    "
                  >
                    <div class="image-input-wrapper" style="position: relative">
                      <img class="image" :src="data.item.photo" />
                    </div>
                  </div>
                </template>
                <template #cell(inventory_status)="data">
                  <b-badge
                    v-if="data.item.inventory_status_id == 1"
                    class="btn-green text-white"
                    style="cursor: pointer"
                  >
                    {{ data.item.inventory_status_name }}
                  </b-badge>
                  <b-badge
                    v-if="data.item.inventory_status_id == 2"
                    class="text-white"
                    variant="danger"
                    style="cursor: pointer"
                  >
                    {{ data.item.inventory_status_name }}
                  </b-badge>
                  <b-badge
                    v-if="data.item.inventory_status_id == 3"
                    class="text-white"
                    variant="info"
                    style="cursor: pointer"
                  >
                    {{ data.item.inventory_status_name }}
                  </b-badge>
                  <b-badge
                    v-if="data.item.inventory_status_id == 4"
                    class="text-white"
                    variant="success"
                    style="cursor: pointer"
                  >
                    {{ data.item.inventory_status_name }}
                  </b-badge>
                  <b-badge
                    v-if="data.item.inventory_status_id == 5"
                    class="text-white"
                    variant="warning"
                    style="cursor: pointer"
                  >
                    {{ data.item.inventory_status_name }}
                  </b-badge>
                </template>
                <template #cell(actions)="data">
                  <template v-if="purpose == 'modal'">
                    <!-- <b-form-checkbox
                      :checked="data.item.checked"
                      :id="`checkbox-pick-item-${data.item.id}`"
                      :name="`checkbox-pick-item-${data.item.id}`"
                      @change="
                        chosenItem({
                          id: data.item.id,
                          name: data.item.name,
                          stock: data.item.inventory_remaining_stock,
                        })
                      "
                    >
                    </b-form-checkbox> -->

                    <b-button
                      size="sm"
                      class="mr-1 btn-info"
                      @click="
                        chosenItem({
                          id: data.item.id,
                          name: data.item.name,
                          stock: data.item.inventory_remaining_stock,
                          uom: data.item.uom_name
                        })
                      "
                    >
                      Pilih
                    </b-button>
                  </template>
                </template>
              </b-table>
              <b-pagination
                v-if="items.length != 0"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                @page-click="pageOnClick"
                class="mt-4"
              ></b-pagination>
            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "List",

  props: {
    purpose: String,
  },
  components: {
    Card,
  },

  data() {
    return {
      // Filter
      filter: {
        name: "",
      },
      // pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 0,
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "photo",
          label: "Foto",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "inventory_category_name",
          label: "Kategori",
          sortable: true,
        },
        {
          key: "inventory_remaining_stock",
          label: "Stok",
          sortable: true,
        },
        {
          key: "inventory_type_name",
          label: "Tipe",
          sortable: true,
        },
        {
          key: "inventory_status",
          label: "Status",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
      manipulateBtn: false,
      dataLoaded: false,
    };
  },

  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },
    async pagination() {
      let filterParams = `&name=${this.filter.name}`;
      let response = await module.pagination(
        "inventories",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
      this.dataLoaded = true;
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("inventories/" + id);
      // If Deleted
      if (result) {
        this.list();
      }
    },

    chosenItem(data) {
      this.$emit("chosenItem", data);
    },

    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        if (access_right[a] == "9003") {
          this.manipulateBtn = true;
        }
      }
    },
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Inventory" }]);
    // Get Data
    this.pagination();
    this.setActiveMenu();
  },
};
</script>
<style scoped>
.image-input-wrapper {
  width: 50px !important;
  height: 50px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.sr-only {
  width: 0px !important;
}
</style>